import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";

const ServiceInfoModal = ({ isOpen, onClose, selectedService }) => {
  const sentences = selectedService.description
    .split(".")
    .filter((sentence) => sentence.trim().length > 0);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mt={"10%"}>
        <ModalHeader>{selectedService.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Image width={"50%"} m={"auto"} src={selectedService.img} />
          <Text>
            {sentences.map((sentence, index) => (
              <Text mt={3} key={index}>
                {sentence.trim()}.
              </Text>
            ))}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ServiceInfoModal;
