import React from 'react'
import styles from "./ourAlliances.module.css";
import icicibank from "./OurAlliancesImages/icici-lombard.jpg";
import yesbank from "./OurAlliancesImages/yes-bank.jpg";
import finobank from "./OurAlliancesImages/fino.jpg";
import airtelPaymentsbank from "./OurAlliancesImages/airtel-payment-bank.jpg";
import bharatbill from "./OurAlliancesImages/bharat-bill-pay.jpg";
import mobikwik from "./OurAlliancesImages/mobikwik.jpg";
import prabhumoney from "./OurAlliancesImages/prabhu-money-transfer.jpg";
import IME from "./OurAlliancesImages/ime-forex.jpg";
import redbus from "./OurAlliancesImages/red-bus.jpg";
import abhibus from "./OurAlliancesImages/abhi-bus.jpg";
import irctc from "./OurAlliancesImages/irctc.jpg";
import travel from "./OurAlliancesImages/Travel-Boutique-Online.jpg";
import globalassure from "./OurAlliancesImages/global-assure.jpg";
import hdfc_argo from "./OurAlliancesImages/hdfc-ergo.jpg";
import digit from "./OurAlliancesImages/digit-insurance.jpg";
import bajajfinserve from "./OurAlliancesImages/bajaj-finsrv.jpg";

const AllianceSlider = () => {
    const alliancesImages = [
        icicibank,
        yesbank,
        finobank,
        airtelPaymentsbank,
        bharatbill,
        mobikwik,
        prabhumoney,
        IME,
        redbus,
        abhibus,
        irctc,
        travel,
        globalassure,
        hdfc_argo,
        digit,
        bajajfinserve,
      ];
  return (
    <div className={styles.auto_slider_container}>
    <ul className={styles.auto_slider}>
      {alliancesImages.map((el, ind) => {
        return (
          <li>
            <img src={el} alt="" />
          </li>
        );
      })}
    </ul>
    <ul className={styles.auto_slider}>
      {alliancesImages.map((el, ind) => {
        return (
          <li key={ind}>
            <img src={el} alt="" />
          </li>
        );
      })}
    </ul>
  </div>
  )
}

export default AllianceSlider
