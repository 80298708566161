import { Box, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import styles from "./contact.module.css";
import cGif from "./ContactUSImages/SPRINT.gif";

const ContactUS = () => {
  return (
    <Box className={styles.contact_usSection} id="contactus">
      <Box
        width={{ base: "100%", sm: "100%", md: "100%", lg: "80%" }}
        margin={"auto"}
        padding={"10px"}
      >
        <Box>
          <Text
            fontWeight={"700"}
            fontSize={"xxx-large"}
            textAlign={"center"}
            className={styles.c_heading}
            marginLeft={{ base: 0, sm: 0, md: 0, lg: "550px" }}
          >
            <b>Contact Us</b>
          </Text>
        </Box>
        <Box
          display={"flex"}
          flexDirection={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "row",
          }}
        >
          <Box
            display={"flex"}
            width={{ base: "100%", sm: "100%", md: "100%", lg: "40%" }}
          >
            <Image
              src={cGif}
              alt="Contact us gif"
              width={"80%"}
              margin={"auto"}
            />
          </Box>
          <Box
            width={{ base: "100%", sm: "100%", md: "100%", lg: "60%" }}
            display={"flex"}
            alignItems={"center"}
            padding={"10px"}
          >
            <Stack spacing={5} width={"100%"}>
              <Text fontSize={"1.3rem"}>
                <b>
                  Address : SEVOKE ROAD PANI TANKI MORE SILIGURI,WEST BENGAL
                </b>
              </Text>
              <Text fontSize={"1.3rem"}>
                <b>Contact Us : +91-9910165583</b>
              </Text>
              <Box display={"flex"}>
                <Text fontSize={"1.3rem"}>
                  <b>Email ID : </b>
                </Text>
                <Text fontSize={"1.3rem"}>
                  <b>
                    <a href="mailto:customercare.prepaynetwork@gmail.com">
                      customercare.prepaynetwork@gmail.com
                    </a>
                  </b>
                </Text>
              </Box>
              <Text fontSize={"1.3rem"}>
                <b>Working Hours : 10:00 AM To 07:00 PM, Sunday Closed</b>
              </Text>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUS;
