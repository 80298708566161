import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import styles from  "./ourAlliances.module.css"

const AlliancesHeading = () => {
  return (
    <Box>
      <Text
            fontWeight={"700"}
            fontSize={"xxx-large"}
            textAlign={"left"}
            color={"white"}
            letterSpacing={5}
          >
            <b>OUR ALLIANCES</b>
          </Text>
    </Box>
    // <SectionHeading heading={"Our Alliances"} />
  )
}

export default AlliancesHeading
