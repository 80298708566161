import { Box, Text } from "@chakra-ui/react";
import React from "react";

const Footerstrip = () => {
  return (
    <Box display={"flex"} p={5}>
      <Box w={"50%"} display={"flex"} justifyContent={"space-around"} alignItems={"center"}>
        <a href="/">Terms & Conditions</a> |<a href="/">Privacy Policy</a> |
        <a href="/">Refund & Cancellation Policy</a>
      </Box>
      <Box w={"50%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Text>Copyright© 2024 Epaisa Pay. All right reserved.</Text>
      </Box>
    </Box>
  );
};

export default Footerstrip;
