import React from "react";
import styles from "./OurServices.module.css";
import { Heading } from "@chakra-ui/react";

const Services = () => {
  return (
    <section className={styles.c_section}>
      <h2 className={styles.c_section__title} id={styles.h2}>
        <span>Our Services</span>
      </h2>
      <ul className={styles.c_services} id={styles.ul}>
        <li className={styles.c_services__item} id={styles.li}>
          <Heading as={"h3"}>Travel & Stay</Heading>
          <p>
            Explore the world hassle-free with EpaisaPay's Travel & Stay
            services. From flights and hotels to car rentals and more, plan your
            dream trip with ease.
          </p>
        </li>
        <li className={styles.c_services__item} id={styles.li}>
          <Heading as={"h3"}>Micro ATM</Heading>
          <p>
            Unlock new revenue streams and expand financial access for your
            customers with EpaisaPay's Micro ATM service. Seamlessly transform
            your retail outlet into a banking hub, offering essential financial
            services right at your fingertips.
          </p>
        </li>
        <li className={styles.c_services__item} id={styles.li}>
          <Heading as={"h3"}>Bharat Bill Payment System</Heading>
          <p>
            Say goodbye to bill payment hassles with EpaisaPay's integration
            with the Bharat Bill Payment System (BBPS). Enjoy the convenience of
            paying all your bills in one place, securely and efficiently.
          </p>
        </li>
        <li className={styles.c_services__item} id={styles.li}>
          <Heading as={"h3"}>Aadhar Enabled Payment Syatem</Heading>
          <p>
            Experience the future of digital payments with EpaisaPay's Aadhaar
            Enabled Payment System (AEPS) integration. Enjoy the convenience of
            secure and hassle-free transactions using just your Aadhaar number.
          </p>
        </li>
        <li className={styles.c_services__item} id={styles.li}>
          <Heading as={"h3"}>Cash Management Services</Heading>
          <p>
            Optimize your cash handling processes and enhance security with
            EpaisaPay's Cash Management Services (CMS). Streamline your cash
            flow and focus on growing your business while we handle the rest.
          </p>
        </li>
        <li className={styles.c_services__item} id={styles.li}>
          <Heading as={"h3"}>Domestic Money Transfer</Heading>
          <p>
            Transfer money to any bank account across India instantly with
            EpaisaPay's DMT service. Experience reliable and convenient
            transfers anytime, anywhere. Say hello to seamless transactions with
            EpaisaPay!
          </p>
        </li>
        {/* <li className={styles.c_services__item} id={styles.li}>
          <Heading as={"h3"}>And Many More...</Heading>
        </li> */}
      </ul>
    </section>
  );
};

export default Services;
