import React from "react";
import AllianceSlider from "./AllianceSlider";
import AlliancesHeading from "./AlliancesHeading";
import { Box } from "@chakra-ui/react";
import styles from "./ourAlliances.module.css"

const OurAlliances = () => {
  return (
    <Box className={styles.ourAlliancesSection}>

    <Box width={{base:"100%", sm:"100%", md:"100%", lg:"80%"}} margin={"auto"} padding={"50px"} marginTop={"50px"}>
      <AlliancesHeading />
      <AllianceSlider />
    </Box>
    </Box>
  );
};

export default OurAlliances;
