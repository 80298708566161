import "./App.css";
import AboutUs from "./Components/AboutUs/AboutUs.jsx";
import ScrollToTopButton from "./Components/Common/ScrollToTop.jsx";
import ContactUS from "./Components/Contact Us/ContactUS.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import Footerstrip from "./Components/Footer/Footerstrip.jsx";
import Header from "./Components/Header/Header";
import Headerbanner from "./Components/Header/Headerbanner";
import OurAlliances from "./Components/OurAlliances/OurAlliances.jsx"
import OurServices from "./Components/OurServices/OurServices.jsx"

function App() {
  return (
    <>
      <Header />
      <Headerbanner />
      <AboutUs/>
      <OurServices />
      <ContactUS/>
      <OurAlliances />
      <Footer/>
      <Footerstrip/>
      <ScrollToTopButton/>
    </>
  );
}

export default App;
