import { Box, Image, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import msmelogo from "./FooterImages/msme-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faGooglePlusG,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./footer.css";

const Footer = () => {
  return (
    <footer
      style={{
        width: "100%",
        background: "#1ca998",
        color: "white",
      }}
    >
      <Box
        width={"90%"}
        display={"flex"}
        flexDirection={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "row",
        }}
        justifyContent={"space-between"}
        margin={"auto"}
        padding={5}
      >
        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "30%" }}>
          <Box>
            <Image
              src={msmelogo}
              ast="MSME Logo"
              w={"30%"}
              background={"#ffff"}
              borderRadius={10}
              p={2}
              m={"auto"}
            />
          </Box>
          <Text textAlign={"center"} mt={3}>
            <b>
              "Welcome to Epaisa Pay, where excellence meets innovation.
              Discover our revolutionary solutions for seamless customer value,
              prioritizing speed and convenience."
            </b>
          </Text>
        </Box>
        <Box
          w={{ base: "100%", sm: "100%", md: "100%", lg: "30%" }}
          display={"flex"}
          justifyContent={"center"}
        >
          <Box>
            <Box ml={-5}>
              <Text>
                <b>Quick Links</b>
              </Text>
            </Box>
            <List spacing={3} mt={5}>
              <ListItem>
                <a href="#home" style={{ fontSize: "1rem" }}>
                  Home
                </a>
              </ListItem>
              <ListItem>
                <a href="#aboutus" style={{ fontSize: "1rem" }}>
                  About Us
                </a>
              </ListItem>
              <ListItem>
                <a href="#services" style={{ fontSize: "1rem" }}>
                  Services
                </a>
              </ListItem>
              <ListItem>
                <a href="#contactus" style={{ fontSize: "1rem" }}>
                  Contact Us
                </a>
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "30%" }}>
          <Box>
            <Box ml={-5}>
              <Text>
                <b>Social Profiles</b>
              </Text>
            </Box>
            <Box className="social-media-icons" mt={5}>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ background: "#804fc2", padding: 8, borderRadius: 5 }}
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ background: "#804fc2", padding: 8, borderRadius: 5 }}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ background: "#804fc2", padding: 8, borderRadius: 5 }}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://plus.google.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ background: "#804fc2", padding: 8, borderRadius: 5 }}
              >
                <FontAwesomeIcon icon={faGooglePlusG} />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ background: "#804fc2", padding: 8, borderRadius: 5 }}
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
{
  /* <Box
width={"80%"}
display={"flex"}
justifyContent={"space-between"}
margin={"auto"}
padding={5}
>
<Box width={"30%"} >
  <Image src={logo} width={"50%"}  margin={"auto"}/>
  <Text mt={"10px"}>
    <b>
      "Welcome to Epaisa Pay, where excellence meets innovation.
      Discover our revolutionary solutions for seamless customer value,
      prioritizing speed and convenience."
    </b>
  </Text>
</Box>
<Box
  width={"30%"}
  display={"flex"}
  justifyContent={"center"}
  alignItems={"center"}
>
  <List spacing={3}>
    <ListItem>
      <a href="#home" style={{ fontSize: "1.3rem" }}>
        <b>Home</b>
      </a>
    </ListItem>
    <ListItem>
      <a href="#aboutus" style={{ fontSize: "1.3rem" }}>
        <b>About Us</b>
      </a>
    </ListItem>
    <ListItem>
      <a href="#services" style={{ fontSize: "1.3rem" }}>
        <b>Services</b>
      </a>
    </ListItem>
    <ListItem>
      <a href="#contactus" style={{ fontSize: "1.3rem" }}>
        <b>Contact Us</b>
      </a>
    </ListItem>
  </List>
</Box>
<SocialMediaIcons />
</Box> */
}
