import React, { useEffect, useState } from "react";
import styles from "./header.module.css";
import logo from "./Headerimages/epaisapay-logo.png";
import {
  Grid,
  GridItem,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { bankingFinTechSol, services, solutions } from "./services";
import ServiceInfoModal from "./ServiceInfoModal";
import digital_india_logo from "./Headerimages/digitalIndia.png";


const Navbar = () => {
  const [showDropDown, setShowDropDwon] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedService, setSelectedService] = useState(null);
  const handleDropDown = () => {
    setShowDropDwon(!showDropDown);
  };
  const [isMobile, setIsamobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsamobile(window.innerWidth <= 768);
      console.log(isMobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    // const navHeight = document.querySelector('.header').offsetHeight; // Height of the navbar
    const navHeight = document.querySelector(`.${styles.header}`).offsetHeight;
    console.log(navHeight);

    if (section) {
      const sectionTop = section.offsetTop - navHeight - 50; // Adjusted top position

      window.scrollTo({
        top: sectionTop,
        behavior: "smooth",
      });
    }
  }

  const handleOpen = (service) => {
    setSelectedService(service);
    onOpen();
  };

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>{" "}
        <div className={styles.nav}>
          <a href="/" onClick={() => handleDropDown("home")}>
            Home
          </a>
          <Popover>
            <PopoverTrigger>
              <a href="#">Products & Services</a>
            </PopoverTrigger>
            <PopoverContent
              width={{ base: "200px", sm: "250px", md: "500px", lg: "800px" }}
            >
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                  }}
                  gap={3}
                  padding={5}
                >
                  {services.map((el, ind) => (
                    <GridItem 
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event propagation to the Popover
                      handleOpen(el);
                    }}
                      _hover={{
                        background: "lightblue",
                        color: "white",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                      key={ind}
                      w={"100%"}
                      h={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Image
                        src={el.img}
                        alt={el.name}
                        w={"60%"}
                        h={"60%"}
                        margin={"auto"}
                      />
                      <Text textAlign={"center"}>
                        <b>{el.name}</b>
                      </Text>
                    </GridItem>
                  ))}
                </Grid>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <Popover>
            <PopoverTrigger>
              <a href="#">Solution</a>
            </PopoverTrigger>
            <PopoverContent
              width={{ base: "200px", sm: "250px", md: "500px", lg: "800px" }}
            >
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(3, 1fr)",
                  }}
                  gap={3}
                  padding={5}
                >
                  {solutions.map((el, ind) => (
                    <GridItem
                      onClick={() => handleOpen(el)}
                      _hover={{
                        background: "lightblue",
                        color: "white",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                      key={ind}
                      w={"100%"}
                      h={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Image
                        src={el.img}
                        alt={el.name}
                        w={"60%"}
                        h={"60%"}
                        margin={"auto"}
                      />
                      <Text textAlign={"center"}>
                        <b>{el.name}</b>
                      </Text>
                    </GridItem>
                  ))}
                </Grid>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger>
              <a href="#">Banking FinTech</a>
            </PopoverTrigger>
            <PopoverContent
              width={{ base: "200px", sm: "250px", md: "500px", lg: "800px" }}
            >
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(3, 1fr)",
                  }}
                  gap={3}
                  padding={5}
                >
                  {bankingFinTechSol.map((el, ind) => (
                    <GridItem onClick={()=> handleOpen(el)}
                      _hover={{
                        background: "lightblue",
                        color: "white",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                      key={ind}
                      w={"100%"}
                      h={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Image
                        src={el.img}
                        alt={el.name}
                        w={"60%"}
                        h={"60%"}
                        margin={"auto"}
                      />
                      <Text textAlign={"center"}>
                        <b>{el.name}</b>
                      </Text>
                    </GridItem>
                  ))}
                </Grid>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <a href="#aboutus" onClick={() => scrollToSection("aboutus")}>
            About Us
          </a>
          <a href="#contactus" onClick={() => scrollToSection("contactus")}>
            Contact Us
          </a>
        </div>
        <div 
        style={{display:"flex", justifyContent:"space-between",  alignItems:
          "center",  width:"20%", 
        }}
        >
          <a href="/Web/EpaisaPayLogin.aspx">
            <button class={styles.button_63}>Login</button>
          </a>
          <Image src={digital_india_logo} alt="digital india logo" w={"40%"} h={"100%"} bgColor={"white"} p={1} borderRadius={"10px"} marginLeft={10}/>
        </div>
      </div>
      {selectedService && <ServiceInfoModal isOpen={isOpen} onClose={onClose} selectedService={selectedService}/>}
    </div>
  );
};

export default Navbar;
