import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import MobileNavbar from "./MobileNavbar";

const Header = () => {
  // const [isMobile, setIsamobile] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsamobile(window.innerWidth <= 768);
  //   };
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [isMobile]);

  // return <>{isMobile ? <MobileNavbar /> : <Navbar isMobile={isMobile}/>}</>;
return <Navbar/>
};

export default Header;
