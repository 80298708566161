import { Box } from "@chakra-ui/react";
import React from "react";
// import OurServicesHeading from "./OurServicesHeading";
import styles from "./OurServices.module.css";
import Services from "./Services";

const OurServices = () => {
  return (
    <Box className={styles.ourServicesSection} id="services">
      <Box width={"80%"} margin={"auto"} marginTop={"50px"}>
        <Services/>
      </Box>
    </Box>
  );
};

export default OurServices;
