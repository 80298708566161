import React, { useState, useEffect } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa'; // Assuming you have react-icons installed for the up arrow icon
import styles from "./common.module.css"

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when user scrolls down 400px
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 400) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // Scroll to the top of the page when button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={styles.scroll_to_top}>
      {isVisible && (
        <button className={styles.scroll_to_top_button} onClick={scrollToTop}>
          <FaArrowCircleUp  />
        </button>
      )}
    </div>
  );
};

export default ScrollToTopButton;
