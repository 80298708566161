import React from "react";
import styles from "./header.module.css";
import { Box, Heading, Icon, Image, Stack, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import banner_gif from "./Headerimages/The Lyft Direct debit card for drivers.gif";
import ajadimahotsavlogo from "./Headerimages/aam.jpeg";

const Headerbanner = () => {
  return (
    <Box
      className={styles.header_banner}
      width={"100%"}
      height={{ base: "auto", sm: "auto", md: "auto", lg: "85vh" }}
      display={"flex"} alignItems={"center"}
    >
      {/* <Box
        background={"#ffff"}
        w={"35%"}
        m={"auto"}
        mb={10}
        padding={2}
        borderRadius={10}
        display={"flex"}
        justifyContent={"space-around"}
      >
        <Image src={digital_india_logo} alt="digital India Logo" w={"20%"} />
        <Image
          src={ajadimahotsavlogo}
          alt="aajadi ka amrit mahotsav"
          w={"20%"}
        />
      </Box> */}
      <Box>
        <Box
          width={"80%"}
          margin={"auto"}
          display={{ base: "flex", sm: "flex", md: "flex", lg: "flex" }}
          flexDirection={{
            base: "column-reverse",
            sm: "column-reverse",
            md: "column-reverse",
            lg: "row",
          }}
        >
          <Box width={{ sm: "100%", md: "100%", lg: "50%" }} color={"white"}>
            <Stack spacing={5}>
              <Heading as={"h1"}>
                Empower Your Business with EpaisaPay: Seamlessly Streamline
                Offline & Online Payments Today!
              </Heading>
              <Stack spacing={3} marginTop={5}>
                <Box display={"flex"} alignItems={"center"} gap={3}>
                  <Icon as={CheckCircleIcon} />
                  <Text>Seamless Online Transactions</Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={3}>
                  <Icon as={CheckCircleIcon} />
                  <Text>Secure Payment Gateways</Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={3}>
                  <Icon as={CheckCircleIcon} />
                  <Text>Instant Payment Processing</Text>
                </Box>
              </Stack>
              <Heading>
                Trust EpaisaPay for Secure, Effortless, and Efficient Payment
                Solutions Tailored for Your Omnichannel Success!
              </Heading>
            </Stack>
          </Box>
          <Box
            boxSize="sm"
            width={{ sm: "100%", md: "100%", lg: "50%" }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Image src={banner_gif} alt="banner gif" width={"100%"} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Headerbanner;
