import dmt from "./Headerimages/dmt.png"
import aeps from "./Headerimages/aeps.png"
import bbps from "./Headerimages/bbps.png"
import cms from "./Headerimages/cms.png"
import matm from "./Headerimages/matm.png"
import fastag from "./Headerimages/fastag.png"
import insurance from "./Headerimages/insurance.png"
import travelandstay from "./Headerimages/travelandstay.png"
import bulkSMS from "./Headerimages/BulSMS.png"
import WBAPI from "./Headerimages/WBapi.png"
import voiceCall from "./Headerimages/voicecall.png"
import gvSMS from "./Headerimages/GoogleVerifiedSMS.png"
import RCS from "./Headerimages/RCS.png"
import chatBot from "./Headerimages/chatbot.png"

//Solutions images
import corporateSol from "./Headerimages/corporatesolutions.png"
import govtSolutions from "./Headerimages/governmentsolutions.png"
import campusSolutions from "./Headerimages/capussolutions.png"

//Banking FinTech Solutions images
import accountOpening from "./Headerimages/accountOpening.png"
import payoutSolutions from "./Headerimages/payoutSolutions.png"
import prepaidCard from "./Headerimages/prepaidCards.png"
import virtualSolutions from "./Headerimages/virtualSolutions.png"
import nepalMOneyTransfer from "./Headerimages/indoNepalRemittance.png"
export const services = [
  {
    name: "Bharat Bill Payment System",
    img: bbps,
    description: "A comprehensive bill payment platform in India. Allows users to pay utility, credit card, loan, and other bills. Ensures secure and quick transactions. Available across multiple channels including online, mobile, and retail outlets. Supports multiple modes of payment like UPI, net banking, and cards."
  },
  {
    name: "FasTag",
    img: fastag,
    description: "An electronic toll collection system in India.Uses RFID technology for automatic toll payments.Reduces congestion at toll plazas and ensures a seamless travel experience.Linked to prepaid or savings/current accounts. Enables cashless transactions and quick passage through toll gates."
  },
  {
    name: "Insurance",
    img: insurance,
    description: "Comprehensive insurance solutions including life, health, and general insurance. Facilitates easy policy purchase, renewals, and claims processing. Provides coverage for a variety of risks and emergencies. Ensures financial protection and peace of mind.Available for individuals, families, and businesses."
  },
  {
    name: "Travel & Stay",
    img: travelandstay,
    description: "A one-stop solution for travel bookings and accommodations. Includes flight, train, and bus bookings. Offers hotel reservations and holiday packages.Ensures best rates and seamless booking experience.Provides 24/7 customer support for travelers.."
  },
  {
    name: "Bulk SMS",
    img: bulkSMS,
    description: "Allows businesses to send SMS messages to a large group of recipients.Ideal for marketing campaigns, notifications, and alerts.Supports both transactional and promotional messages. Ensures high delivery rates and real-time reporting. Can be integrated with various applications and platforms."
  },
  {
    name: "WhatsApp Business API",
    img: WBAPI,
    description: "Enables businesses to communicate with customers on WhatsApp. Supports automated messages, customer support, and marketing. Enhances customer engagement and satisfaction. Provides secure and reliable messaging solutions.Integrates with CRM and other business systems."
  },
  {
    name: "Voice Call",
    img: voiceCall,
    description: "Facilitates automated voice calls to a large audience. Ideal for reminders, alerts, and marketing campaigns. Supports both pre-recorded and interactive voice response (IVR) messages. Ensures high-quality voice communication. Provides detailed analytics and reporting."
  },
  {
    name: "Google Verified SMS",
    img: gvSMS,
    description: "Ensures that SMS messages are verified and trusted by Google. Enhances brand credibility and customer trust. Reduces the risk of phishing and spam. Supports sender verification and branding. Improves message delivery rates and engagement."
  },
  {
    name: "RCS",
    img: RCS,
    description: "An advanced messaging protocol that enhances SMS capabilities. Supports multimedia messages, group chats, and file sharing. Provides a rich and interactive messaging experience. Enhances customer engagement and satisfaction. Compatible with most modern smartphones."
  },
  {
    name: "Chat Bot",
    img: chatBot,
    description: "AI-powered chatbots for customer support and engagement. Available 24/7 to handle queries and provide information. Enhances customer service and reduces response time. Can be integrated with websites, apps, and social media platforms. Supports multiple languages and customization."
  }
];


export const solutions = [
  {
    name:"Corporate Solutions", 
    img:corporateSol, 
    description:"Tailored financial and communication solutions for businesses.Includes payroll management, expense tracking, and bulk messaging. Enhances operational efficiency and employee satisfaction. Ensures secure and compliant financial transactions. Provides detailed analytics and reporting for better decision-making."
  },
  {
    name:"Government Solutions", 
    img:govtSolutions, 
    description:"Digital solutions for government agencies and public sector organizations. Includes e-governance, public grievance systems, and bulk communication. Enhances transparency and efficiency in public services. Supports secure and efficient financial transactions. Provides real-time reporting and analytics."
  },
  {
    name:"Campus Solutions", 
    img:campusSolutions,
    description:"Comprehensive solutions for educational institutions. Includes fee payment systems, attendance tracking, and bulk messaging. Enhances communication between administration, students, and parents. Supports secure and efficient financial transactions. Provides detailed reporting and analytics."
  }
]

export const bankingFinTechSol = [
  {
    name: "Domestic Money Trasfer",
    img: dmt,
    description:"Comprehensive solutions for educational institutions.Includes fee payment systems, attendance tracking, and bulk messaging. Enhances communication between administration, students, and parents. Supports secure and efficient financial transactions. Provides detailed reporting and analytics."
  },
  {
    name: "Aadhar Enabled Payment System",
    img: aeps,
    description:"Facilitates financial transactions using Aadhaar authentication.Supports cash deposits, withdrawals, and balance inquiries. Enhances financial inclusion and accessibility. Ensures secure and efficient transactions. Available at banking correspondent outlets."
  },
  {
    name: "Cash Management System",
    img: cms,
    description:"Streamlines cash collection, handling, and reconciliation. Ensures secure and efficient cash management. Provides real-time tracking and reporting. Reduces operational risks and costs. Enhances financial control and visibility."
  },
  {
    name: "CSP",
    img: cms,
    description:"Provides banking services in remote and underserved areas. Supports account opening, deposits, withdrawals, and more. Enhances financial inclusion and accessibility. Operated by authorized agents and correspondents. Ensures secure and efficient transactions."
  },
  {
    name: "Micro ATM",
    img: matm,
    description:"Portable devices for banking transactions in remote areas. Supports cash deposits, withdrawals, and balance inquiries. Enhances financial inclusion and accessibility.Ensures secure and efficient transactions.Compatible with AEPS and other banking services."
  },
  {
    name: "Account Opening",
    img: accountOpening,
    description:"Facilitates easy and quick bank account opening. Supports both digital and physical KYC processes. Enhances customer convenience and satisfaction. Ensures compliance with regulatory standards. Provides detailed tracking and reporting."
  },
  {
    name: "Payout Solution",
    img: payoutSolutions,
    description:"Enables bulk disbursement of payments to beneficiaries. Ideal for salary payments, vendor payments, and refunds. Ensures secure and efficient transactions. Provides real-time tracking and reporting. Enhances operational efficiency and control."
  },
  {
    name: "Prepaid cards",
    img: prepaidCard,
    description:"Enables bulk disbursement of payments to beneficiaries. Ideal for salary payments, vendor payments, and refunds. Ensures secure and efficient transactions. Provides real-time tracking and reporting. Enhances operational efficiency and control."
  },
  {
    name: "Virtual Solutions",
    img: virtualSolutions,
    description: "Digital financial solutions for modern businesses. Includes virtual cards, wallets, and online banking. Enhances convenience and security in transactions. Supports integration with various platforms and systems. Provides detailed tracking and reporting."
  },
  {
    name: "Nepal Money Transfer",
    img: nepalMOneyTransfer,
    description:"Facilitates secure and instant money transfers to Nepal. Supports both bank account and cash pickup transfers. Ensures compliance with regulatory standards. Provides real-time tracking and reporting. Enhances customer convenience and satisfaction."
  },
]