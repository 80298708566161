import { Box, Heading, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import aboutusImage from "./AboutUsImages/aboutus.png";
import aboutusImage2 from "./AboutUsImages/aboutus3.png";
import styles from "./aboutus.module.css";

const AboutUs = () => {
  return (
    <Box
      marginTop={"50px"}
      id="aboutus"
      position={"relative"}
      width={"100%"}
    >
      <Box width={{ sm: "100%", md: "80%", lg: "80%" }} margin={"auto"}>
        <Box>
          <Text
            fontWeight={"700"}
            fontSize={{
              base: "x-large",
              sm: "large",
              md: "x-large",
              lg: "xxx-large",
            }}
            textAlign={"center"}
            className={styles.text}
            marginLeft={{base:0, sm:0, md:0, lg:"550px"}}
          >
            <b>About Us</b>
          </Text>
        </Box>
        <Box>
          <Box
            display={"flex"}
            flexDirection={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "row",
            }}
          >
            <Box
              width={{ base: "100%", sm: "100%", md: "100%", lg: "40%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Image
                src={aboutusImage2}
                width={{ base: "50%", sm: "50%", md: "50%", lg: "80%" }}
                alt="about us image 2"
              />
            </Box>
            <Box width={{ base: "100%", sm: "100%", md: "100%", lg: "60%" }}>
              <Heading as={"h2"} textAlign={"center"}>
                Empowering Businesses, Empowering Lives
              </Heading>
              <Stack spacing={6}>
                <Text
                  marginTop={"15px"}
                  textAlign={"justify"}
                  fontSize={"1.3rem"}
                >
                  <b>
                    At <span className={styles.text}>EpaisaPay</span>, we
                    believe that technology should be inclusive, not exclusive.
                    It's a fundamental right accessible to all, not just a
                    privilege. EpaisaPay stands firm on this belief.
                  </b>
                </Text>
                <Text fontSize={"1.3rem"} textAlign={"justify"}>
                  <b>
                    We offer a comprehensive suite of payment solutions through
                    our robust Agent Network, leveraging Semi-closed Wallets and
                    our innovative Neo Banking Platform. Our agents provide a
                    wide range of services including Domestic Money Transfer
                    (DMT), cash withdrawal, Mini ATM (AEPS), PAN Card
                    applications, travel bookings, insurance, BBPS, Indo Nepal
                    Money Transfer, and much more.
                  </b>
                </Text>
                <Text fontSize={"1.3rem"} textAlign={"justify"}>
                  <b>
                    EpaisaPay is more than just a platform. It's a commitment to
                    accessibility, reliability, and empowerment for businesses
                    and individuals alike. Join us in our mission to make
                    financial technology truly inclusive."
                  </b>
                </Text>
              </Stack>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={{
            base: "column-reverse",
            sm: "column-reverse",
            md: "column-reverse",
            lg: "row",
          }}
          marginTop={"25px"}
        >
          <Box
            width={
              {base:"100%", sm:"100%", md:"100%", lg:"60%"}
            }
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-evenly"}
          >
            <Heading as={"h2"} textAlign={"center"} marginTop={"15px"}>
              EpaisaPay: Where Every Transaction Tells a Story
            </Heading>

            <Text fontSize={"1.3rem"} textAlign={"justify"}>
              <b>
                <span className={styles.text}>EpaisaPay </span>
                is more than just a platform. It's a commitment to inclusivity,
                innovation, and empowerment. It's about bridging the gap between
                aspiration and achievement, between dreams and reality. Join us
                in our mission to make financial technology truly inclusive.
                Together, let's unlock the full potential of digital finance and
                create a brighter, more prosperous future for all.
              </b>
            </Text>
          </Box>
          <Box
            width={{ base: "100%", sm: "100%", md: "100%", lg: "40%" }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={aboutusImage} alt="about us image" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
